import React, {ChangeEvent, ChangeEventHandler, FocusEventHandler} from "react";
import {AreaInfo, PlantTypeInfo} from "../model/Finding";
import {ItemRenderer, Select} from "@blueprintjs/select";
import {Button, MenuItem} from "@blueprintjs/core";
import useFetchData from "../utils/useFetchData";

export type EntitySelectProps<T> = {
    fetchUrl: string,
    activeItem: T | null,
    name: string,
    placeholder?: string,
    onChange: ChangeEventHandler<T | null>,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    disabled: boolean,
    required: boolean,
    getId: (t: T) => string,
    getText: (t: T) => string
};

export function EntitySelect<T>(props: EntitySelectProps<T>) {
    const [entities] = useFetchData<T>(props.fetchUrl);

    const itemRenderer: ItemRenderer<T> = (item, {handleClick, handleFocus, modifiers}) => {
        if (!modifiers.matchesPredicate)
            return null;
        return (
            <MenuItem active={modifiers.active}
                      disabled={modifiers.disabled}
                      key={props.getId(item)}
                      onClick={handleClick}
                      onFocus={handleFocus}
                      roleStructure="listoption"
                      text={props.getText(item)}/>
        );
    };

    const onItemSelect = (item: T) => {
        const target = {value: item, name: props.name};
        const fakeEvent = {
            target,
            currentTarget: target
        } as unknown as ChangeEvent<T | null>;
        props.onChange(fakeEvent);
    };

    return <Select<T>
        items={entities}
        activeItem={props.activeItem}
        itemRenderer={itemRenderer}
        onItemSelect={onItemSelect}
        disabled={props.disabled}
        fill
        inputProps={{
            name: props.name,
            onBlur: props.onBlur,
            required: props.required
        }}>
        <Button text={props.activeItem? props.getText(props.activeItem) : ''}
                fill
                rightIcon="double-caret-vertical"
                placeholder={props.placeholder || ''} />
    </Select>;
}

export type AreaSelectProps = {
    activeItem: AreaInfo | null,
    name: string,
    placeholder?: string,
    onChange: ChangeEventHandler<AreaInfo | null>,
    onBlur?: FocusEventHandler<HTMLInputElement> | undefined,
    disabled: boolean,
    required: boolean
}

export function AreaSelect(props: AreaSelectProps) {
    return <EntitySelect<AreaInfo>
        fetchUrl={`${process.env.REACT_APP_API}/v1/area?archived=true`}
        {...props}
        getId={area => area.id}
        getText={area => area.name} />
}

export type PlantTypeSelectProps = {
    activeItem: PlantTypeInfo | null,
    name: string,
    placeholder?: string,
    onChange: ChangeEventHandler<PlantTypeInfo | null>,
    onBlur?: FocusEventHandler<HTMLInputElement> | undefined,
    disabled: boolean,
    required: boolean
}

export function PlantTypeSelect(props: PlantTypeSelectProps) {
    return <EntitySelect<PlantTypeInfo>
        fetchUrl={`${process.env.REACT_APP_API}/v1/plant-types?archived=true`}
        {...props}
        getId={plantType => plantType.id}
        getText={plantType => plantType.name} />
}