import React, {useCallback, useEffect, useState} from "react";
import * as bp from "@blueprintjs/core";

export type ImageManagerProps = {
    isOpen: boolean,
    onClose: () => void,
    title: string,
    loadUrls: () => Promise<string[]>,
    addImage: (file: File) => Promise<void>,
    deleteImage: (url: string) => Promise<void>
};

function SlideShow({imageUrls, deleteImage}: {
    imageUrls: string[],
    deleteImage: (imageUrl: string) => Promise<void>
}) {
    const [index, setIndex] = useState<number>(0);
    const [imageUrl, setImageUrl] = useState<string>(imageUrls[index]);
    useEffect(() => setImageUrl(imageUrls[index]), [index, imageUrls, setImageUrl]);
    const hasNext = () => index < imageUrls.length - 1;
    const next = () => hasNext() && setIndex(index + 1);
    const hasPrev = () => index > 0;
    const prev = () => hasPrev() && setIndex(index - 1);

    var fileName = imageUrl.slice(imageUrl.lastIndexOf('/') + 1);
    if (fileName.length > 32) {
        const extension = fileName.slice(fileName.lastIndexOf('.'));
        fileName = fileName.slice(0, 29) + '..' + extension;
    }

    const openImageUrl = () => window.open(imageUrl, '_blank');

    return <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px', justifyContent: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '10px'}}>
            <bp.AnchorButton icon="arrow-left" intent={bp.Intent.PRIMARY} outlined disabled={!hasPrev()}
                             onClick={prev}/>
            <img src={imageUrl} alt={`Bild ${index + 1} von ${imageUrls}`} style={{width: '200px'}}/>
            <bp.AnchorButton icon="arrow-right" intent={bp.Intent.PRIMARY} outlined disabled={!hasNext()}
                             onClick={next}/>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '5px'}}>
            <bp.Button icon="download"
                       intent={bp.Intent.PRIMARY}
                       outlined
                       onClick={openImageUrl}>
                {index + 1}/{imageUrls.length} {fileName}
            </bp.Button>
            <bp.AnchorButton icon="trash" intent={bp.Intent.DANGER} onClick={() => {
                setIndex(0);
                deleteImage(imageUrl);
            }}/>
        </div>
    </div>;
}

export function AddImageComponent({addImage}: { addImage: (file: File) => Promise<void> }) {
    const [file, setFile] = useState<File | null | undefined>();
    const [state, setState] = useState<'none' | 'loading' | 'success' | 'error'>();
    const [error, setError] = useState<string | null | undefined>();
    const upload = useCallback(async () => {
        if (!file) return;
        setState('loading');
        setError(undefined);
        try {
            await addImage(file);
            setState('success');
        } catch (e) {
            setState('error');
            if (e instanceof Error) {
                setError(e.message);
            } else {
                setError('Bild konnte nicht hinzugefügt werden.');
            }
        }
    }, [setState, setError, file, addImage]);
    return <div style={{display: 'flex', flexDirection: 'row', columnGap: '5px', alignItems: 'center'}}>
        <bp.FormGroup label="Bilddatei" labelInfo=".jpg, .jpeg, .png, .webp">
            <bp.FileInput onInputChange={e => setFile(e.currentTarget.files?.[0])}/>
        </bp.FormGroup>
        <bp.AnchorButton icon="upload"
                         intent={state === 'error' ? bp.Intent.DANGER : (state === 'success' ? bp.Intent.SUCCESS : bp.Intent.PRIMARY)}
                         outlined
                         onClick={upload}
                         disabled={!file || state === 'loading'}>
            {state === 'loading' && <bp.Spinner/>}
            {state === 'error' && `Fehler beim Hochladen! ${error || ''}`}
            {state === 'success' && 'Hochgeladen!'}
        </bp.AnchorButton>
    </div>;
}

export function ImageManager({addImage, deleteImage, isOpen, loadUrls, onClose, title}: ImageManagerProps) {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    useEffect(() => {
        loadUrls().then(setImageUrls);
    }, [loadUrls, setImageUrls]);
    const addImageCb = useCallback(async (file: File) => {
        await addImage(file);
        setImageUrls(await loadUrls());
    }, [addImage, loadUrls, setImageUrls]);
    const deleteImageCb = useCallback(async (imageUrl: string) => {
        await deleteImage(imageUrl);
        setImageUrls(await loadUrls());
    }, [deleteImage, loadUrls, setImageUrls]);

    return <bp.Dialog {...{title, isOpen, onClose}}
                      isCloseButtonShown={true}
                      icon="camera">
        <bp.DialogBody>
            {imageUrls.length === 0 && <bp.NonIdealState icon="camera" title="Keine Bilder"/>}
            {imageUrls.length > 0 && <SlideShow imageUrls={imageUrls} deleteImage={deleteImageCb}/>}
        </bp.DialogBody>
        <bp.DialogFooter>
            <AddImageComponent addImage={addImageCb}/>
        </bp.DialogFooter>
    </bp.Dialog>;
}

export type ImageButtonProps = {
    imageUrls: string[],
    openDialog: () => void
};

export function ImageButton(props: ImageButtonProps) {
    return <bp.Button icon="camera" onClick={props.openDialog} fill>
        {props.imageUrls.length} Bild{props.imageUrls.length !== 1 && 'er'}
    </bp.Button>;
}