import {Area} from "../../model/Area";
import * as rt from "@tanstack/react-table";
import * as bp from "@blueprintjs/core";
import React, {useState} from "react";
import {AppTable, ColorCell, RowActions, SlicedCell} from "../../components/AppTable";
import {openGeoJson} from "../../utils/geojson";
import {ImageButton, ImageManager, ImageManagerProps} from "../../components/ImageManager";
import {AreasDialog, AreasDialogProps} from "./AreaDialog";
import AreaService from "../../service/AreaService";

export default function AreasTable({areas: data, refreshData: refreshAddresses}: { areas: Area[], refreshData: () => Promise<void> }) {
    const [dialogProps, setDialogProps] = useState<AreasDialogProps>({
        isOpen: false,
        close: async () => {
            setDialogProps({...dialogProps, isOpen: false});
            await refreshAddresses();
        },
        mode: 'new',
        area: {
            id: '',
            name: '',
            description: '',
            website: '',
            address: {
                streetAddress: '',
                postalCode: '',
                country: 'at'
            },
            color: '#ff0000',
            polygon: {},
            archived: false,
            previewImages: [],
            findings: 0
        }
    });
    const openCreateDialog = () => setDialogProps({
        ...dialogProps,
        isOpen: true,
        mode: 'new',
        area: {
            id: '',
            name: '',
            description: '',
            website: '',
            address: {
                streetAddress: '',
                postalCode: '',
                country: 'at'
            },
            color: '#ff0000',
            polygon: {},
            archived: false,
            previewImages: [],
            findings: 0
        }
    });
    const openEditDialog = (area: Area) => setDialogProps({
        ...dialogProps,
        isOpen: true,
        mode: 'edit',
        area
    });
    const [imageManagerProps, setImageManagerProps] = useState<ImageManagerProps>({
        isOpen: false,
        onClose: async () => {
            setImageManagerProps({...imageManagerProps, isOpen: false});
            await refreshAddresses();
        },
        title: '',
        loadUrls: async () => [],
        addImage: async (_) => {},
        deleteImage: async (_) => {}
    });
    const columnHelper = rt.createColumnHelper<Area>();
    const columns = [
        columnHelper.accessor('archived', {
            cell: info => <bp.Checkbox checked={!info.getValue()} disabled/>,
            header: () => <span>Aktiv</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: () => <span>Name</span>
        }),
        columnHelper.accessor('description', {
            cell: info => <SlicedCell text={info.getValue()} max={100}/>,
            header: () => <span>Beschreibung</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('address', {
            cell: info => `${info.getValue().streetAddress}, ${info.getValue().postalCode}`,
            header: () => <span>Adresse</span>
        }),
        columnHelper.accessor('website', {
            cell: info =>
                <bp.AnchorButton icon="link"
                                 onClick={() => window.open(info.getValue(), '_blank')}
                                 disabled={info.getValue() === ''}
                                 outlined/>,
            header: () => <span>Website</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('color', {
            cell: info => <ColorCell color={info.getValue()}/>,
            header: () => <span>Farbe</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('polygon', {
            cell: info =>
                <bp.Button icon="map"
                           text="Öffnen"
                           onClick={() => openGeoJson(info.getValue())}/>,
            header: () => <span>Karte</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('findings', {
            cell: info => info.getValue(),
            header: () => <span>Aufzeichnungen</span>,
            enableColumnFilter: false
        }),
        columnHelper.accessor('previewImages', {
            cell: info =>
                <ImageButton imageUrls={info.getValue()}
                             openDialog={() => setImageManagerProps({
                                 ...imageManagerProps,
                                 isOpen: true,
                                 loadUrls: async () => (await AreaService.getArea(info.row.original.id)).previewImages,
                                 addImage: async (file) => await AreaService.uploadImage(info.row.original, file),
                                 deleteImage: async (url) =>
                                     await AreaService.removeImage(info.row.original, url.substring(url.lastIndexOf('/') + 1))
                             })}/>,
            header: () => <span>Bilder</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.display({
            id: 'actions',
            cell: ({row: {original: area}}) =>
                <RowActions edit={() => openEditDialog(area)}
                            delete={async () => await AreaService.deleteArea(area)}/>,
            header: () => <span>Aktionen</span>
        })
    ];
    const [sorting, setSorting] = useState<rt.SortingState>([{id: 'name', desc: false}]);
    const [columnFilters, setColumnFilters] = useState<rt.ColumnFiltersState>([]);
    const table = rt.useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters
        },
        getCoreRowModel: rt.getCoreRowModel(),
        getFilteredRowModel: rt.getFilteredRowModel(),
        getSortedRowModel: rt.getSortedRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters
    });
    return <>
        <ImageManager {...imageManagerProps} />
        <AreasDialog {...dialogProps} />
        <AppTable table={table} create={openCreateDialog} />
    </>;
}