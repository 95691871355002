export const readJsonFile = (file: File, callback: (json: any) => void, errorCallback: (error: Error) => void) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
        const text = e.target?.result;
        try {
            const json = JSON.parse(text as string);
            callback(json);
        } catch (error) {
            errorCallback(error instanceof Error ? error : new Error(String(error)));
        }
    };
    reader.readAsText(file);
};
