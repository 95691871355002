import {Finding, mapFindingToGeoJSONProperties} from "../../model/Finding";
import * as rt from "@tanstack/react-table";
import React, {useCallback, useState} from "react";
import {AppTable, DateRangeFilter, RowActions} from "../../components/AppTable";
import * as bp from "@blueprintjs/core";
import {openAllGeoJson, openGeoJson} from "../../utils/geojson";
import {RenderTaleProps} from "../main/MainView";
import {DateTime} from "luxon";
import {ExportFn} from "../../components/ActionsBar";
import FindingsService from "../../service/FindingsService";
import {fetchWithToken} from "../../utils/fetchWithToken";
import {FindingsDialog, FindingsDialogProps} from "./FindingsDialog";

export default function FindingsTable({data, refreshData: refreshFindings}: RenderTaleProps<Finding>) {
    const [dialogProps, setDialogProps] = useState<FindingsDialogProps>({
        isOpen: false,
        close: async () => {
            setDialogProps({...dialogProps, isOpen: false});
            await refreshFindings();
        },
        mode: 'new'
    });
    const openCreateDialog = () => setDialogProps({
        ...dialogProps,
        isOpen: true,
        mode: 'new'
    });
    const columnHelper = rt.createColumnHelper<Finding>();
    const columns = [
        columnHelper.accessor('area.name', {
            cell: info => info.getValue(),
            header: () => <span>Schutzgebiet</span>,
            filterFn: 'equalsString'
        }),
        columnHelper.group({
            columns: [
                columnHelper.accessor('plantType.name', {
                    cell: info => info.getValue(),
                    header: 'Name',
                    filterFn: 'equalsString'
                }),
                columnHelper.accessor('plantType.scientificName', {
                    cell: info => info.getValue(),
                    header: 'Wissenschaftlicher Name',
                    filterFn: 'equalsString'
                })
            ],
            header: 'Pflanzenart'
        }),
        columnHelper.accessor('amount', {
            cell: info => info.getValue(),
            header: () => <span>Anzahl</span>
        }),
        columnHelper.accessor('polygon', {
            cell: info =>
                <bp.Button icon="map"
                           text="Öffnen"
                           onClick={() => openGeoJson(info.getValue(), mapFindingToGeoJSONProperties(info.row.original))}/>,
            header: () => <span>Karte</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('created', {
            cell: info => DateTime.fromISO(info.getValue()).toLocaleString({}),
            header: () => <span>Erstellt am</span>,
            filterFn: DateRangeFilter
        }),
        columnHelper.accessor('user.email', {
            cell: info => info.getValue(),
            header: () => <span>Erstellt von</span>,
            filterFn: 'equalsString'
        }),
        columnHelper.accessor('creationMode', {
            cell: info => {
                const creationMode = info.getValue();
                if(creationMode === 'WEB') {
                    return <bp.Tag>web</bp.Tag>;
                } else if(creationMode === 'MOBILE') {
                    return <bp.Tag>mobile</bp.Tag>;
                }
                return '';
            },
            header: () => <span>Erstellt auf</span>,
            filterFn: 'equalsString'
        }),
        columnHelper.display({
            id: 'actions',
            cell: info =>
                <RowActions delete={async () => {
                    await FindingsService.deleteFinding(info.row.original);
                    await refreshFindings();
                }}/>,
            header: () => <span>Aktionen</span>
        })
    ];
    const [sorting, setSorting] = useState<rt.SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<rt.ColumnFiltersState>([]);
    const table = rt.useReactTable({
        data: data,
        columns,
        state: {
            sorting,
            columnFilters
        },
        getCoreRowModel: rt.getCoreRowModel(),
        getSortedRowModel: rt.getSortedRowModel(),
        getFilteredRowModel: rt.getFilteredRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters
    });
    const exportFns: ExportFn[] = [
        {
            label: '.csv',
            fn: async () => {
                const response = await fetchWithToken(process.env.REACT_APP_API + '/api/v2/findings/export/csv', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(table.getFilteredRowModel().flatRows.map(row => row.original.id))
                });

                if (response.status !== 200) {
                    throw new Error('Oh no!'); // FIXME - More specific error handling
                }

                const blob = await response.blob();
                const fileUrl = window.URL.createObjectURL(blob);

                // Create an anchor element and trigger download
                const downloadLink = document.createElement('a');
                downloadLink.href = fileUrl;
                downloadLink.download = "export.csv";
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Clean up
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileUrl);
            }
        },
        { // FIXME code duplication
            label: '.kml',
            fn: async () => {
                const response = await fetchWithToken(process.env.REACT_APP_API + '/api/v2/findings/export/kml', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(table.getFilteredRowModel().flatRows.map(row => row.original.id))
                });

                if (response.status !== 200) {
                    throw new Error('Oh no!'); // FIXME - More specific error handling
                }

                const blob = await response.blob();
                const fileUrl = window.URL.createObjectURL(blob);

                // Create an anchor element and trigger download
                const downloadLink = document.createElement('a');
                downloadLink.href = fileUrl;
                downloadLink.download = "export.kml";
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Clean up
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileUrl);
            }
        },
        { // FIXME code duplication
            label: 'GeoJSON',
            fn: async () => {
                const response = await fetchWithToken(process.env.REACT_APP_API + '/api/v2/findings/export/geojson', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(table.getFilteredRowModel().flatRows.map(row => row.original.id))
                });

                if (response.status !== 200) {
                    throw new Error('Oh no!'); // FIXME - More specific error handling
                }

                const blob = await response.blob();
                const fileUrl = window.URL.createObjectURL(blob);

                // Create an anchor element and trigger download
                const downloadLink = document.createElement('a');
                downloadLink.href = fileUrl;
                downloadLink.download = "export.json";
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Clean up
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(fileUrl);
            }
        }
    ];
    const openAll = useCallback(async () => {
        openAllGeoJson(table.getFilteredRowModel()
            .flatRows.map(row => row.original)
            .map(f => {return {geojson: f.polygon, properties: mapFindingToGeoJSONProperties(f)};})
        );
    }, [table]);
    return <>
        <AppTable table={table}
                  create={openCreateDialog}
                  exportFns={exportFns}
                  openAll={openAll} />
        <FindingsDialog {...dialogProps} />
    </>;
}
