import React, {useCallback, useState} from "react";
import * as bp from "@blueprintjs/core";
import {Formik, FormikHelpers} from 'formik';
import {PlantType} from "../../model/PlantType";
import PlantTypeService from "../../service/PlantTypeService"; // Import PlantTypeService

export type PlantTypesDialogProps = {
    isOpen: boolean,
    close: () => void,
    mode: 'new' | 'edit',
    plantType: PlantType
};

export function PlantTypesDialog(props: PlantTypesDialogProps) {
    const [error, setError] = useState<string | null>(null);

    const submit = useCallback(async (values: PlantType, helpers: FormikHelpers<PlantType>) => {
        setError(null);
        try {
            if (props.mode === 'new') {
                await PlantTypeService.createPlantType(values);
            } else if (props.mode === 'edit') {
                await PlantTypeService.updatePlantType(props.plantType.id, values);
            }
            props.close();
        } catch (e) {
            setError(e instanceof Error ? e.message : 'An error occurred');
        }
    }, [props]);

    return (
        <bp.Dialog isOpen={props.isOpen}
                   onClose={props.close}
                   isCloseButtonShown={true}
                   icon={props.mode === 'new' ? "add" : "edit"}
                   title={props.mode === 'new' ? "Neue Pflanzenart" : props.plantType.name}>
            <Formik initialValues={props.plantType} onSubmit={submit}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <bp.DialogBody>
                            <bp.FormGroup label="Name">
                                <bp.InputGroup name="name"
                                               value={values.name}
                                               onChange={handleChange}
                                               disabled={isSubmitting}
                                               placeholder="Einfacher Name"
                                               required/>
                            </bp.FormGroup>
                            <bp.FormGroup label="Wissenschaftlicher Name">
                                <bp.InputGroup name="scientificName"
                                               value={values.scientificName}
                                               onChange={handleChange}
                                               disabled={isSubmitting}
                                               placeholder="Wissenschaftlicher Name"
                                               required/>
                            </bp.FormGroup>
                            <bp.FormGroup label="Beschreibung">
                                <bp.TextArea name="description"
                                             value={values.description}
                                             onChange={handleChange}
                                             disabled={isSubmitting}
                                             fill
                                             maxLength={2048}/>
                            </bp.FormGroup>
                            <bp.FormGroup>
                                <bp.Checkbox name="archived"
                                             checked={values.archived}
                                             onChange={handleChange}
                                             disabled={isSubmitting}>Aktiv
                                </bp.Checkbox>
                            </bp.FormGroup>
                        </bp.DialogBody>
                        <bp.DialogFooter>
                            {error && <bp.Callout intent="danger">{error}</bp.Callout>}
                            <bp.Button intent={bp.Intent.SUCCESS}
                                       type="submit"
                                       outlined
                                       icon="saved"
                                       text="Speichern"
                                       disabled={isSubmitting}/>
                            <bp.Button intent={bp.Intent.DANGER}
                                       onClick={props.close}
                                       outlined
                                       icon="trash"
                                       text="Abbrechen"/>
                        </bp.DialogFooter>
                    </form>
                )}
            </Formik>
        </bp.Dialog>
    );
}
