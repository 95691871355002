import * as bp from "@blueprintjs/core";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";

export default function LoginView() {
    const {loginWithRedirect} = useAuth0();
    return <>
        <bp.Navbar>
            <bp.NavbarGroup>
                <bp.NavbarHeading>Naturland NÖ Monitor</bp.NavbarHeading>
            </bp.NavbarGroup>
            <bp.Navbar.Group align={bp.Alignment.RIGHT}>
                <bp.Button icon="log-in"
                           onClick={() => loginWithRedirect()}>Anmelden
                </bp.Button>
            </bp.Navbar.Group>
        </bp.Navbar>
        <img src={'webapp-header-hq.jpg'} alt="Header" />
    </>;
}