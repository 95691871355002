import {IdToken} from "@auth0/auth0-react";

let globalGetAccessTokenSilently: () => Promise<IdToken | undefined>;

export const setGlobalGetAccessTokenSilently = (fn: () => Promise<IdToken | undefined>) => {
    globalGetAccessTokenSilently = fn;
};

export const fetchWithToken = async (url: string, options?: RequestInit): Promise<Response> => {
    const idToken = await globalGetAccessTokenSilently();
    if(idToken) {
        const authOptions = {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: `Bearer ${idToken.__raw}`,
            },
        };
        return fetch(url, authOptions);
    }
    return fetch(url, options);
};