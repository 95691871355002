// PlantTypeService.ts
import BaseService from './BaseService';
import {PlantType, NewPlantType, PlantTypeUpdates} from '../model/PlantType';
import {ImageUploadRequestDTO, ImageUploadUrl} from '../model/Image';

class PlantTypeService extends BaseService {
    private baseUrl = `${process.env.REACT_APP_API}/v1/plant-types`;

    async createPlantType(newPlantTypeData: NewPlantType): Promise<PlantType> {
        return this.fetchWithHandling<PlantType>(`${this.baseUrl}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newPlantTypeData)
        });
    }

    async listPlantTypes(): Promise<PlantType[]> {
        return this.fetchWithHandling<PlantType[]>(this.baseUrl);
    }

    async getPlantType(id: string): Promise<PlantType> {
        return this.fetchWithHandling<PlantType>(`${this.baseUrl}/${id}`);
    }

    async updatePlantType(id: string, updates: PlantTypeUpdates): Promise<PlantType> {
        return this.fetchWithHandling<PlantType>(`${this.baseUrl}/${id}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(updates)
        });
    }

    async deletePlantType(plantType: PlantType): Promise<void> {
        if(plantType.findings === 0) {
            if(window.confirm(`Wollen Sie die Pflanzenart ${plantType.name} wirklich löschen?`)) {
                await this.fetchWithHandling<void>(`${this.baseUrl}/${plantType.id}`, {
                    method: 'DELETE'
                });
            }
        } else {
            window.alert(`Die Plfanzenart ${plantType.name} hat ${plantType.findings} bestehende Aufzeichnungen und kann daher nicht
             gelöscht, sondern nur archiviert werden. Dann wird es auch in den mobilen Apps nicht mehr angezeigt
             werden.`);
        }
    }

    async generateImageUploadUrl(id: string, uploadUrlBody: ImageUploadRequestDTO): Promise<ImageUploadUrl> {
        return this.fetchWithHandling<ImageUploadUrl>(`${this.baseUrl}/${id}/images`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(uploadUrlBody)
        });
    }

    async uploadImage(plantType: PlantType, file: File): Promise<void> {
        const {url} = await this.generateImageUploadUrl(plantType.id, {path: file.name});
        await this.fetchWithHandling(url, {
            method: 'PUT',
            headers: {'Content-Type': file.type},
            body: await file.arrayBuffer()
        }, false);
    }

    async removeImage(plantType: PlantType, fileName: string): Promise<void> {
        return this.fetchWithHandling<void>(`${this.baseUrl}/${plantType.id}/images`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({path: fileName})
        });
    }
}

const pts = new PlantTypeService();
export default pts;
