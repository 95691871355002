import BaseService from './BaseService';
import {AreaInfo, Finding, PlantTypeInfo, UserInfo} from '../model/Finding';

class FindingsService extends BaseService {
    private baseUrl = `${process.env.REACT_APP_API}/v1/findings`;

    async listAllFindings(since?: Date): Promise<Finding[]> {
        const queryParams = since ? `?since=${since.toISOString()}` : '';
        return this.fetchWithHandling<Finding[]>(`${this.baseUrl}${queryParams}`);
    }

    async deleteFinding(finding: Finding): Promise<void> {
        if(window.confirm(`Wollen Sie die Aufzeichnung ${finding.area.name}, ${finding.plantType.name} (Anzahl: ${finding.amount}) wirklich löschen?`)) {
            return this.fetchWithHandling<void>(`${this.baseUrl}/${finding.id}`, {
                method: 'DELETE'
            });
        }
    }

    async createFinding(newFindingData: {
        area: AreaInfo,
        plantType: PlantTypeInfo,
        amount: number,
        user: UserInfo,
        created: Date | null,
        creationMode: 'UNKNOWN' | 'MOBILE' | 'WEB'
    }): Promise<Finding> {
        return this.fetchWithHandling<Finding>(`${process.env.REACT_APP_API}/api/v2/findings`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newFindingData)
        });
    }
}

const fs = new FindingsService();
export default fs;
