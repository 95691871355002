import React from "react";
import {MainView, RenderTaleProps} from "../main/MainView";
import FindingsTable from "./FindingsTable";
import {Finding} from "../../model/Finding";

export default function Findings() {
    return <MainView subject="Aufzeichnungen"
                     slug="Hier können Sie die neuesten Aufzeichnungen exportieren."
                     fetchPath={`${process.env.REACT_APP_API}/api/v2/findings`}
                     lifeRefresh={true}
                     renderTable={(props: RenderTaleProps<Finding>) =>
                         <FindingsTable {...props} />}/>;
}