function sanityCheckCoordinates(coordinates: any[]) {
    if(coordinates[0] > coordinates[1]) {
        return [coordinates[1], coordinates[0]];
    }
    return coordinates;
}

function makeValidGeoJson(geojson: any, properties: any = {}) {
    // Check if the geojson is already in a valid format
    if (geojson.type === "FeatureCollection") {
        return geojson;
    } else if (geojson.coordinates && geojson.coordinates.length === 1) {
        // Single coordinate, format as Point
        return {
            type: "FeatureCollection",
            features: [{
                type: "Feature",
                properties: {...properties},
                geometry: {
                    type: "Point",
                    coordinates: sanityCheckCoordinates(geojson.coordinates[0])
                }
            }]
        };
    } else if (geojson.coordinates) {
        // Multiple coordinates, format as LineString (or Polygon if needed)
        return {
            type: "FeatureCollection",
            features: [{
                type: "Feature",
                properties: {...properties},
                geometry: {
                    type: "LineString", // Change to "Polygon" if these are polygon coordinates
                    coordinates: geojson.coordinates.map(sanityCheckCoordinates)
                }
            }]
        };
    } else {
        throw new Error("Invalid GeoJSON input");
    }
}

export function openGeoJson(geojson: any, properties: any = {}) {
    let validGeoJson = makeValidGeoJson(geojson, properties);
    const url = `http://geojson.io/#data=data:application/json,${encodeURIComponent(JSON.stringify(validGeoJson))}`;
    return window.open(url, '_blank');
}

export function openAllGeoJson(geojsonArray: {geojson: any, properties: any}[]) {
    const combinedGeoJson = {
        type: "FeatureCollection",
        features: []
    };

    geojsonArray.map(({geojson: gj, properties}) => makeValidGeoJson(gj, properties)).forEach(geojson => {
        if (geojson.type === "Feature") {
            // @ts-ignore
            combinedGeoJson.features.push(geojson);
        } else if (geojson.type === "FeatureCollection" && geojson.features) {
            // @ts-ignore
            combinedGeoJson.features.push(...geojson.features);
        } else {
            console.warn("Invalid GeoJSON object:", geojson);
        }
    });

    const url = `http://geojson.io/#data=data:application/json,${encodeURIComponent(JSON.stringify(combinedGeoJson))}`;
    return window.open(url, '_blank');

}