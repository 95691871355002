import React, {useCallback, useEffect, useState} from "react";
import * as bp from "@blueprintjs/core";
import * as bpd from "@blueprintjs/datetime2";
import {AreaInfo, PlantTypeInfo, UserInfo} from "../../model/Finding";
import {FormikHelpers} from "formik/dist/types";
import {Formik} from "formik";
import {AreaSelect, PlantTypeSelect} from "../../components/EntitySelect";
import FindingsService from "../../service/FindingsService";
import deAT from "date-fns/locale/de-AT";

export type FindingsDialogProps = {
    isOpen: boolean,
    close: () => void,
    mode: 'new'
};

type FindingsFormState = {
    plantType: PlantTypeInfo | null,
    amount: number,
    area: AreaInfo | null,
    user: UserInfo,
    created: Date | null,
    polygon: any | undefined
};

export function FindingsDialog(props: FindingsDialogProps) {
    const [error, setError] = useState<string | null>(null);
    // Reset error when dialog is opened or closed
    useEffect(() => {
        setError(null);
    }, [props.isOpen]);
    const handleError = (e: any) => {
        if (e instanceof Error) {
            setError(e.message);
        } else {
            setError('An error occurred');
        }
    };

    const submit = useCallback(async (values: FindingsFormState, helpers: FormikHelpers<FindingsFormState>) => {
        setError(null);

        try {
            if (props.mode === 'new') {
                const {area, plantType, amount, user, created} = values;
                if(area && plantType && user) {
                    await FindingsService.createFinding({
                        area,
                        plantType,
                        amount,
                        user,
                        created,
                        creationMode: 'WEB'
                    });
                    props.close();
                }
            }
        } catch (e) {
            handleError(e);
        }
    }, [props]);

    const initialValues: FindingsFormState = {
        plantType: null,
        amount: 1,
        area: null,
        user: {
            email: ''
        },
        created: (new Date()),
        polygon: undefined
    };

    return <bp.Dialog isOpen={props.isOpen}
                      onClose={props.close}
                      isCloseButtonShown={true}
                      icon={"add"}
                      title={"Neue Aufnahme"}>
        <Formik initialValues={initialValues} onSubmit={submit}>
            {({
                  handleSubmit,
                  resetForm,
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  isSubmitting
              }) =>
                <form onSubmit={handleSubmit}>
                    <bp.DialogBody>
                        <bp.FormGroup label="Schutzgebiet">
                            <AreaSelect name="area"
                                        activeItem={values.area}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={isSubmitting}
                                        required/>
                        </bp.FormGroup>
                        <bp.FormGroup label="Pflanzentyp">
                            <PlantTypeSelect name="plantType"
                                             activeItem={values.plantType}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             disabled={isSubmitting}
                                             required/>
                        </bp.FormGroup>
                        <bp.FormGroup label="Aufnahmedatum">
                            <bpd.DatePicker3 value={values.created} locale={deAT} onChange={e => {
                                setFieldValue('created', e);
                            }} />
                        </bp.FormGroup>
                        <bp.FormGroup label="Anzahl">
                            <bp.NumericInput name="amount"
                                             fill
                                             value={values.amount}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             disabled={isSubmitting}
                                             min={1}
                                             max={500}
                                             required/>
                        </bp.FormGroup>
                        <bp.FormGroup label="Benutzer">
                            <bp.InputGroup name="user.email"
                                           fill
                                           value={values.user.email}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           disabled={isSubmitting}
                                           maxLength={256}
                                           required/>
                        </bp.FormGroup>
                    </bp.DialogBody>
                    <bp.DialogFooter>
                        {error && <bp.Callout intent="danger">{error}</bp.Callout>}
                        <bp.Button intent={bp.Intent.SUCCESS}
                                   type="submit"
                                   outlined
                                   icon="saved"
                                   text="Speichern"/>
                        <bp.Button intent={bp.Intent.DANGER}
                                   onClick={() => {
                                       resetForm();
                                       props.close();
                                   }}
                                   outlined
                                   icon="trash"
                                   text="Abbrechen"/>
                    </bp.DialogFooter>
                </form>
            }
        </Formik>
    </bp.Dialog>;
}
