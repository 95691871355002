import React, {useEffect, useState} from "react";
import * as bp from "@blueprintjs/core";

type AutoRefreshButtonProps = {
    refreshData: () => Promise<void>,
    intervalSeconds?: number
};

export function AutoRefreshButton({refreshData, intervalSeconds = 30}: AutoRefreshButtonProps) {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [countdown, setCountdown] = useState(intervalSeconds);

    useEffect(() => {
        let intervalId : any;

        if (isRefreshing) {
            intervalId = setInterval(() => {
                setCountdown(prev => prev > 0 ? prev - 1 : intervalSeconds);
                if (countdown === 1) {
                    refreshData()
                        .catch(console.log);
                }
            }, 1000);
        } else {
            setCountdown(intervalSeconds);
        }

        return () => clearInterval(intervalId);
    }, [isRefreshing, countdown, refreshData, intervalSeconds]);

    const handleRefreshToggle = () => {
        setIsRefreshing(!isRefreshing);
    };

    return (
        <bp.Button intent={bp.Intent.NONE}
                   outlined
                   icon={isRefreshing ? "pause" : "play"}
                   text={isRefreshing ? `Refreshing in ${countdown}s` : "Auto Refresh"}
                   onClick={handleRefreshToggle}/>
    );
}
