import {PlantType} from "../../model/PlantType";
import * as rt from "@tanstack/react-table";
import * as bp from "@blueprintjs/core";
import React, {useState} from "react";
import {AppTable, RowActions, SlicedCell} from "../../components/AppTable";
import {ImageButton, ImageManager, ImageManagerProps} from "../../components/ImageManager";
import {PlantTypesDialog, PlantTypesDialogProps} from "./PlantTpesDialog";
import PlantTypeService from "../../service/PlantTypeService";

export default function PlantTypesTable({plantTypes: data, refreshData: refreshPlantTypes}: {
    plantTypes: PlantType[],
    refreshData: () => Promise<void>
}) {
    const [dialogProps, setDialogProps] = useState<PlantTypesDialogProps>({
        isOpen: false,
        close: async () => {
            setDialogProps({...dialogProps, isOpen: false});
            await refreshPlantTypes();
        },
        mode: 'new',
        plantType: {
            id: '',
            name: '',
            scientificName: '',
            description: '',
            archived: false,
            previewImages: [],
            findings: 0
        }
    });
    const openCreateDialog = () => setDialogProps({
        ...dialogProps,
        isOpen: true,
        mode: 'new',
        plantType: {
            id: '',
            name: '',
            scientificName: '',
            description: '',
            archived: false,
            previewImages: [],
            findings: 0
        }
    });
    const openEditDialog = (plantType: PlantType) => setDialogProps({
        ...dialogProps,
        isOpen: true,
        mode: 'edit',
        plantType
    });
    const [imageManagerProps, setImageManagerProps] = useState<ImageManagerProps>({
        isOpen: false,
        onClose: async () => {
            setImageManagerProps({...imageManagerProps, isOpen: false});
            await refreshPlantTypes();
        },
        title: '',
        loadUrls: async () => [],
        addImage: async (_)  => {},
        deleteImage: async (_) => {}
    });
    const columnHelper = rt.createColumnHelper<PlantType>();
    const columns = [
        columnHelper.accessor('archived', {
            cell: info => <bp.Checkbox checked={!info.getValue()} disabled/>,
            header: () => <span>Aktiv</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: () => <span>Name</span>,
        }),
        columnHelper.accessor('scientificName', {
            cell: info => info.getValue(),
            header: () => <span>Wissenschaftlicher Name</span>
        }),
        columnHelper.accessor('description', {
            cell: info => <SlicedCell text={info.getValue()} max={100}/>,
            header: () => <span>Beschreibung</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.accessor('previewImages', {
            cell: info =>
                <ImageButton imageUrls={info.getValue()}
                             openDialog={() => setImageManagerProps({
                                 ...imageManagerProps,
                                 isOpen: true,
                                 loadUrls: async () => (await PlantTypeService.getPlantType(info.row.original.id)).previewImages,
                                 addImage: async (file) => await PlantTypeService.uploadImage(info.row.original, file),
                                 deleteImage: async (url) => await PlantTypeService.removeImage(info.row.original,  url.substring(url.lastIndexOf('/') + 1))
                             })}/>,
            header: () => <span>Bilder</span>,
            enableSorting: false,
            enableColumnFilter: false
        }),
        columnHelper.display({
            id: 'actions',
            cell: info =>
                <RowActions edit={() => openEditDialog(info.row.original)}
                            delete={async () => {
                                await PlantTypeService.deletePlantType(info.row.original);
                                await refreshPlantTypes();
                            }}/>,
            header: () => <span>Aktionen</span>
        })
    ];
    const [sorting, setSorting] = useState<rt.SortingState>([{id: 'name', desc: false}]);
    const [columnFilters, setColumnFilters] = useState<rt.ColumnFiltersState>([]);
    const table = rt.useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters
        },
        getCoreRowModel: rt.getCoreRowModel(),
        getSortedRowModel: rt.getSortedRowModel(),
        getFilteredRowModel: rt.getFilteredRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters
    });
    return <>
        <ImageManager {...imageManagerProps} />
        <PlantTypesDialog {...dialogProps} />
        <AppTable table={table} create={openCreateDialog} />
    </>;
}