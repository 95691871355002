import React from "react";
import * as bp from "@blueprintjs/core";
import {AutoRefreshButton} from "./AutoRefreshButton";

export type ContentHeaderProps = {
    title: string,
    slug: string,
    lifeRefresh: boolean,
    refresh: () => Promise<void>
};

export function ContentHeader({title, slug, lifeRefresh, refresh}: ContentHeaderProps) {
    return <section>
        <bp.Card elevation={bp.Elevation.TWO}>
            <h1>{title}</h1>
            <h3>{slug}</h3>
            {lifeRefresh && <AutoRefreshButton refreshData={refresh} />}
        </bp.Card>
    </section>;
}