import React, {ReactElement} from "react";
import {ContentHeader} from "../../components/ContentHeader";
import {EmptyStateView, ErrorStateView, LoadingStateView} from "../../components/States";
import useFetchData from "../../utils/useFetchData";

export type RenderTaleProps<T> = {
    data: T[],
    refreshData: () => Promise<void>
};

export type MainVieProps<T> = {
    subject: string,
    slug: string | undefined,
    fetchPath: string,
    renderTable: (props: RenderTaleProps<T>) => ReactElement,
    lifeRefresh?: boolean
};

export function MainView<T>(props: MainVieProps<T>) {
    const [data, loading, error, refreshData] = useFetchData<T>(props.fetchPath);

    return <main style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2vh',
        padding: '1vh'
    }}>
        <ContentHeader title={props.subject} slug={props.slug || ''} lifeRefresh={props.lifeRefresh || false}
                       refresh={refreshData}/>
        {loading && <LoadingStateView/>}
        {error && <ErrorStateView message={error.message} developerMessage={error.name}/>}
        {!loading && !error && (data.length === 0
            ? <EmptyStateView/>
            : <>{props.renderTable({data, refreshData})}</>)}
    </main>;
}