import { fetchWithToken } from '../utils/fetchWithToken';

class BaseService {
    protected async fetchWithHandling<T>(url: string, options?: RequestInit, doFetchWithToken: boolean = true): Promise<T> {
        try {
            const response = doFetchWithToken? await fetchWithToken(url, options) : await fetch(url, options);
            if (!response.ok) {
                // Handle HTTP error responses
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
            // Check if the response has content
            if (response.status === 204 || response.headers.get("content-length") === "0") {
                return null as unknown as T; // Cast null to T
            }
            return await response.json() as T;
        } catch (error) {
            // Handle network or other errors
            console.error("Fetch Error:", error);
            throw error;
        }
    }
}

export default BaseService;