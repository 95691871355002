import BaseService from './BaseService';
import {Area, NewAreaDTO, AreaUpdates } from '../model/Area';
import {ImageUploadRequestDTO, ImageUploadUrl} from '../model/Image';

class AreaService extends BaseService {
    private baseUrl = `${process.env.REACT_APP_API}/v1/area`;

    AreaService() {
    }

    async createArea(newAreaData: NewAreaDTO): Promise<Area> {
        return this.fetchWithHandling<Area>(`${this.baseUrl}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newAreaData)
        });
    }

    async listAreas(archived: boolean = false): Promise<Area[]> {
        return this.fetchWithHandling<Area[]>(`${this.baseUrl}?archived=${archived}`);
    }

    async getArea(id: string): Promise<Area> {
        return this.fetchWithHandling<Area>(`${this.baseUrl}/${id}`);
    }

    async updateArea(id: string, updates: AreaUpdates): Promise<Area> {
        return this.fetchWithHandling<Area>(`${this.baseUrl}/${id}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(updates)
        });
    }

    async deleteArea(area: Area): Promise<void> {
        if(area.findings === 0) {
            if(window.confirm(`Wollen Sie das Schutzgebiet ${area.name} wirklich löschen?`)) {
                await this.fetchWithHandling<void>(`${this.baseUrl}/${area.id}`, {
                    method: 'DELETE'
                });
            }
        } else {
            window.alert(`Das Schutzgebiet ${area.name} hat ${area.findings} bestehende Aufzeichnungen und kann daher nicht
             gelöscht, sondern nur archiviert werden. Dann wird es auch in den mobilen Apps nicht mehr angezeigt
             werden.`);
        }
    }

    async generateImageUploadUrl(area: Area, uploadUrlBody: ImageUploadRequestDTO): Promise<ImageUploadUrl> {
        return this.fetchWithHandling<ImageUploadUrl>(`${this.baseUrl}/${area.id}/images`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(uploadUrlBody)
        });
    }

    async uploadImage(area: Area, file: File): Promise<void> {
        const {url} = await this.generateImageUploadUrl(area, { path: file.name });
        await this.fetchWithHandling(url, {
            method: 'PUT',
            headers: {'Content-Type': file.type},
            body: await file.arrayBuffer()
        }, false);
    }

    async removeImage(area: Area, fileName: string): Promise<void> {
        return this.fetchWithHandling<void>(`${this.baseUrl}/${area.id}/images`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({path: fileName})
        });
    }

    async setPolygon(id: string, polygon: any): Promise<void> {
        return this.fetchWithHandling<void>(`${this.baseUrl}/${id}/polygon`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(polygon)
        });
    }
}

const as = new AreaService();
export default as;
