import {useState, useEffect, useCallback} from 'react';
import { fetchWithToken } from './fetchWithToken';

function useFetchData<T>(url: string): [T[], boolean, Error | null, () => Promise<void>] {
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetchWithToken(url);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }
            const jsonData = await response.json() as T[];
            setData(jsonData);
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, [url]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [data, loading, error, fetchData];
}

export default useFetchData;
