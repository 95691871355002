import React from "react";
import {PlantType} from "../../model/PlantType";
import PlantTypesTable from "./PlantTypesTable";
import {MainView} from "../main/MainView";

export default function PlantTypes() {
    return <MainView subject="Pflanzenart"
                     slug="Hier können Sie Pflanzenarten hinzufügen und bearbeiten."
                     fetchPath={`${process.env.REACT_APP_API}/v1/plant-types`}
                     renderTable={({data, refreshData}: { data: PlantType[], refreshData: () => Promise<void> }) =>
                         <PlantTypesTable plantTypes={data} refreshData={refreshData} />}/>;
}