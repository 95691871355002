import React from "react";
import AreasTable from "./AreasTable";
import {MainView} from "../main/MainView";
import {Area} from "../../model/Area";

export default function Areas() {
    return <MainView subject="Schutzgebiete"
                     slug="Hier können Sie neue Schutzgebiete hinzufügen, entfernen oder verändern."
                     fetchPath={`${process.env.REACT_APP_API}/v1/area?archived=true`}
                     renderTable={({data, refreshData}: { data: Area[], refreshData: () => Promise<void> }) =>
                         <AreasTable areas={data} refreshData={refreshData}/>}/>;
}