import React, {useCallback, useEffect, useState} from "react";
import * as bp from "@blueprintjs/core";
import {Formik} from 'formik';
import {FormikErrors, FormikHelpers} from "formik/dist/types";
import {Area} from "../../model/Area";
import AreaService from "../../service/AreaService";
import {SketchPicker} from 'react-color';
import {openGeoJson} from "../../utils/geojson";
import {readJsonFile} from "../../utils/fileUtils";
import * as tj from "@tmcw/togeojson";

export type AreasDialogProps = {
    isOpen: boolean,
    close: () => void,
    mode: 'new' | 'edit',
    area: Area
};

export function AreasDialog(props: AreasDialogProps) {
    const [error, setError] = useState<string | null>(null);
    // Reset error when dialog is opened or closed
    useEffect(() => {
        setError(null);
    }, [props.isOpen]);
    const handleError = (e: any) => {
        if (e instanceof Error) {
            setError(e.message);
        } else {
            setError('An error occurred');
        }
    };

    const submit = useCallback(async (values: Area, helpers: FormikHelpers<Area>) => {
        setError(null);

        try {
            if (props.mode === 'new') {
                await AreaService.createArea(values);
            } else if (props.mode === 'edit') {
                await AreaService.updateArea(props.area.id || '', values);
            }
            props.close();
        } catch (e) {
            handleError(e);
        }
    }, [props]);
    const handleFileChange = (event: React.FormEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<Area>>) => {
        const input = event.target as HTMLInputElement;
        const file = input.files?.[0];
        if (file) {
            if(file.name.endsWith('.json') || file.name.endsWith('.geojson')) {
                readJsonFile(file,
                    (json) => {
                        setFieldValue("polygon", json)
                            .catch(handleError);
                    },
                    handleError
                );
            } else if(file.name.endsWith('.kml')) {
                file.text().then(kmlText => {
                    console.log(kmlText);
                    const kml = new DOMParser().parseFromString(kmlText, "application/xml" );
                    console.log(kml);
                    const converted = tj.kml(kml);
                    console.log(converted);
                    setFieldValue('polygon', converted).catch(handleError);
                });
            }
        }
    };

    return <bp.Dialog isOpen={props.isOpen}
                      onClose={props.close}
                      isCloseButtonShown={true}
                      icon={props.mode === 'new' ? "add" : "edit"}
                      title={props.mode === 'new' ? "Neues Schutzgebiet" : props.area.name}>
        <Formik initialValues={props.area} onSubmit={submit}>
            {({
                  handleSubmit,
                  resetForm,
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  isSubmitting
              }) =>
                <form onSubmit={handleSubmit}>
                    <bp.DialogBody>
                        <bp.FormGroup label="Name">
                            <bp.InputGroup name="name"
                                           value={values.name}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           disabled={isSubmitting}
                                           placeholder="Einfacher Name"
                                           min={1}
                                           max={32}
                                           required/>
                        </bp.FormGroup>
                        <bp.FormGroup label="Website">
                            <bp.InputGroup name="website"
                                           value={values.website}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           disabled={isSubmitting}
                                           placeholder="https://..."
                                           max={512}/>
                        </bp.FormGroup>
                        <bp.ControlGroup vertical>
                            <bp.FormGroup label="Adresse">
                                <bp.InputGroup name="address.streetAddress"
                                               value={values.address?.streetAddress}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               disabled={isSubmitting}
                                               placeholder="Straße"/>
                            </bp.FormGroup>
                            <bp.FormGroup>
                                <bp.InputGroup name="address.postalCode"
                                               value={values.address?.postalCode}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               disabled={isSubmitting}
                                               placeholder="PLZ"/>
                            </bp.FormGroup>
                        </bp.ControlGroup>
                        <bp.FormGroup label="Beschreibung">
                            <bp.TextArea name="description"
                                         value={values.description}
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         disabled={isSubmitting}
                                         fill
                                         maxLength={2048}/>
                        </bp.FormGroup>
                        <bp.FormGroup>
                            <bp.Checkbox name="archived"
                                         checked={values.archived}
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         disabled={isSubmitting}>
                                Archiviert
                            </bp.Checkbox>
                        </bp.FormGroup>
                        <bp.Divider/>
                        <br/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '10px',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{display: 'flex', flexDirection: 'column', rowGap: '5px'}}>
                                <bp.FormGroup label="GeoJSON Datei">
                                    <bp.FileInput placeholder="location.json"
                                                  onInputChange={change => handleFileChange(change, setFieldValue)} />
                                </bp.FormGroup>
                                <bp.Button icon="map"
                                           onClick={() => openGeoJson(values.polygon)}
                                           disabled={!values.polygon || Object.keys(values.polygon).length === 0}>Karte anzeigen
                                </bp.Button>
                            </div>
                            <SketchPicker color={values.color}
                                          onChange={color => setFieldValue('color', color.hex)}/>
                        </div>
                    </bp.DialogBody>
                    <bp.DialogFooter>
                        {error && <bp.Callout intent="danger">{error}</bp.Callout>}
                        <bp.Button intent={bp.Intent.SUCCESS}
                                   type="submit"
                                   outlined
                                   icon="saved"
                                   text="Speichern"/>
                        <bp.Button intent={bp.Intent.DANGER}
                                   onClick={() => {
                                       resetForm();
                                       props.close();
                                   }}
                                   outlined
                                   icon="trash"
                                   text="Abbrechen"/>
                    </bp.DialogFooter>
                </form>
            }
        </Formik>

    </bp.Dialog>;
}