import * as bp from "@blueprintjs/core";
import React from "react";
import * as rr from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

export default function HomeBar() {
    const {logout} = useAuth0();
    const links = [
        {to: '/areas', label: 'Schutzgebiete'},
        {to: '/plantTypes', label: 'Pflanzenarten'},
        {to: '/findings', label: 'Aufzeichnungen'}
    ];
    return <bp.Navbar>
        <bp.Navbar.Group align={bp.Alignment.LEFT}>
            <bp.NavbarHeading>Naturland NÖ Monitor</bp.NavbarHeading>
            {links.map(({to, label}) => <React.Fragment key={to}>
                <bp.Divider/>
                <rr.Link to={to}>{label}</rr.Link>
            </React.Fragment>)}
        </bp.Navbar.Group>
        <bp.Navbar.Group align={bp.Alignment.RIGHT}>
            <bp.Button icon="log-out"
                       onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>Abmelden
            </bp.Button>
        </bp.Navbar.Group>
    </bp.Navbar>;
}