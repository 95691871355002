import React, {useEffect} from 'react';
import * as rr from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";
import {setGlobalGetAccessTokenSilently} from './utils/fetchWithToken';
import './App.css';
import HomeBar from "./components/HomeBar";
import Areas from "./views/areas/Areas";
import PlantTypes from "./views/plantTypes/PlantTypes";
import Findings from "./views/findings/Findings";
import LoginView from "./views/login/LoginView";

function App() {
    const {isAuthenticated, getIdTokenClaims} = useAuth0();
    useEffect(() => {
        setGlobalGetAccessTokenSilently(getIdTokenClaims);
    }, [getIdTokenClaims]);
    return (
        isAuthenticated ? <div className="App">
            <header>
                <HomeBar/>
            </header>
            <rr.Routes>
                <rr.Route path='/' Component={Areas}/>
                <rr.Route path='/areas' Component={Areas}/>
                <rr.Route path='/plantTypes' Component={PlantTypes}/>
                <rr.Route path='/findings' Component={Findings}/>
            </rr.Routes>
        </div> : <LoginView/>
    );
}

export default App;
