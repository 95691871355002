export type PlantTypeInfo = {
    id: string,
    name: string,
    scientificName: string
};

export type AreaInfo = {
    id: string,
    name: string
};

export type UserInfo = {
    email: string
};

export type Finding = {
    id: string,
    plantType: PlantTypeInfo,
    amount: number,
    area: AreaInfo,
    user: UserInfo,
    created: string,
    polygon: any,
    creationMode: 'UNKNOWN' | 'MOBILE' | 'WEB'
};

export function mapFindingToGeoJSONProperties(finding: Finding): any {
    return {
        "Anzahl": finding.amount,
        "Pflanze": finding.plantType.name,
        "Erstellt von": finding.user?.email,
        "Erstellt am": finding.created
    };
}
