import React from "react";
import * as bp from "@blueprintjs/core";

export type ExportFn = {
    label: string
    fn: () => Promise<void>
};

type ActionBarProps = {
    create?: () => void,
    exports?: ExportFn[],
    openMap?: () => void,
    reset?: () => void,
    results?: number,
    openAll?: () => void
};

export function ActionsBar(props: ActionBarProps = {}) {
    return <section>
        <bp.Card elevation={bp.Elevation.TWO}>
            <bp.ButtonGroup>
                {props.create &&
                    <bp.Button intent={bp.Intent.SUCCESS}
                               outlined
                               icon="new-object"
                               text="Erstellen"
                               onClick={props.create}/>}

                {props.openMap &&
                    <bp.Button intent={bp.Intent.PRIMARY}
                               outlined
                               icon="map-marker"
                               text="Karte"
                               onClick={props.openMap}/>}
                {props.exports && <bp.ButtonGroup>
                    {props.exports.map(({label, fn}) =>
                        <bp.AnchorButton key={label}
                                         icon="download"
                                         intent={bp.Intent.PRIMARY}
                                         outlined
                                         disabled={props.results === 0}
                                         text={label}
                                         onClick={fn}/>)}
                </bp.ButtonGroup>}
                {props.reset &&
                    <bp.Button intent={bp.Intent.WARNING}
                               outlined
                               icon="reset"
                               text="Filter zurücksetzen"
                               onClick={props.reset}/>}
                {props.openAll &&
                    <bp.Button intent={bp.Intent.PRIMARY}
                               outlined
                               icon="map"
                               text="Übersichtskarte"
                               onClick={props.openAll}/>}
            </bp.ButtonGroup>
            <bp.Divider/>
            {!!props.results && <p>{props.results} Ergebnisse</p>}
        </bp.Card>
    </section>;
}