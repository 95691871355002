import * as rt from "@tanstack/react-table";
import * as bp from "@blueprintjs/core";
import * as bpd from '@blueprintjs/datetime2';
import * as bps from '@blueprintjs/select';
import {FilterFnOption, flexRender} from "@tanstack/react-table";
import React from "react";
import {DateTime} from "luxon";
import {ActionsBar, ExportFn} from "./ActionsBar";

export const DateRangeFilter: FilterFnOption<any> = (row, columnId, filterValue) => {
    if (!filterValue)
        return true;
    const [fromDate, toDate] = filterValue as [Date, Date];
    const from = DateTime.fromJSDate(fromDate);
    const to = DateTime.fromJSDate(toDate);
    const value = row.getValue(columnId) as string;
    const dateTime = DateTime.fromISO(value);
    return from <= dateTime && dateTime <= to;
};

function Filter({
                    column,
                    table,
                }: {
    column: rt.Column<any, unknown>
    table: rt.Table<any>
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [firstValue, column]
    );

    const timestampRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
    if (`${firstValue}`.match(timestampRegex)) {
        return <bpd.DateRangeInput3 formatDate={date => DateTime.fromJSDate(date).toLocaleString()}
                                    parseDate={str => {
                                        try {
                                            return DateTime.fromFormat(str, 'DD/MM/YYYY').toJSDate();
                                        } catch (e) {
                                            return false;
                                        }
                                    }}
                                    onChange={column.setFilterValue}/>;
    }

    if (typeof firstValue !== 'number' && column.getFilterFn() === rt.filterFns.equalsString) {
        const onlyUnique = (value: string, index: number, array: string[]) => {
            return array.indexOf(value) === index;
        };
        const items = table.getPreFilteredRowModel()
            .flatRows.map(row => row.getValue(column.id) as string)
            .filter(onlyUnique)
            .sort();
        return <bps.Select2 items={items}
                            itemRenderer={(item, {handleClick, handleFocus, modifiers, query}) => {
                                if (!modifiers.matchesPredicate) {
                                    return null;
                                }
                                if (query && !item.includes(query)) {
                                    return null;
                                }
                                return (
                                    <bp.MenuItem
                                        active={modifiers.active}
                                        disabled={modifiers.disabled}
                                        key={item}
                                        label={item}
                                        onClick={handleClick}
                                        onFocus={handleFocus}
                                        roleStructure="listoption"
                                        text={item}
                                    />
                                );
                            }}
                            onItemSelect={column.setFilterValue}>
            <bp.Button text={(columnFilterValue ?? '') as string} rightIcon="double-caret-vertical"
                       placeholder="Select"/>
        </bps.Select2>;
    }

    return typeof firstValue === 'number' ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                    }
                    placeholder={`Min ${
                        column.getFacetedMinMaxValues()?.[0]
                            ? `(${column.getFacetedMinMaxValues()?.[0]})`
                            : ''
                    }`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old: [number, number]) => [old?.[0], value])
                    }
                    placeholder={`Max ${
                        column.getFacetedMinMaxValues()?.[1]
                            ? `(${column.getFacetedMinMaxValues()?.[1]})`
                            : ''
                    }`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1"/>
        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value}/>
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder="Suche..."
                className="w-36 border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1"/>
        </>
    )
}

// A debounced input react component
function DebouncedInput({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value, debounce, onChange])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}

export type AppTableProps<T> = {
    table: rt.Table<T>,
    exportFns?: ExportFn[], // FIXME no loading state or errors
    create?: () => void,
    openAll?: () => void
};

export function AppTable<T>({table, exportFns, create, openAll}: AppTableProps<T>) {
    return <>
        <ActionsBar results={table.getFilteredRowModel().flatRows.length}
                    exports={exportFns}
                    create={create}
                    reset={table.getState().columnFilters.length > 0 ? () => table.setColumnFilters([]) : undefined}
                    openAll={openAll}/>
        <section>
            <bp.Card elevation={bp.Elevation.TWO}>
                <bp.HTMLTable striped bordered style={{width: '100%'}}>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : (<div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {header.column.getCanSort() &&
                                                <span style={{cursor: 'pointer'}}
                                                      onClick={header.column.getToggleSortingHandler()}>
                                        {' '}
                                                    <small><bp.Icon
                                                        icon={(header.column.getIsSorted() ? `sort-${header.column.getIsSorted()}` : 'sort') as bp.IconName}/></small>
                                    </span>}
                                        </div>)}
                                    {!header.isPlaceholder && header.column.getCanFilter() ? (
                                        <div>
                                            <Filter column={header.column} table={table}/>
                                        </div>
                                    ) : null}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </bp.HTMLTable>
            </bp.Card>
        </section>
    </>;
}

export function SlicedCell({text, max}: { text: string, max: number }) {
    const slicedText = text.length > max ? text.slice(0, max) + '...' : text;
    return <span>{slicedText}</span>;
}

export function ColorCell({color}: { color: string }) {
    return <span style={{border: `2px solid ${color}`, padding: '3px'}}>
        {color}
    </span>;
}

export type RowActionProps = {
    edit?: () => void,
    delete?: () => void
};

export function RowActions(props: RowActionProps) {
    return <>
        {props.edit && <bp.AnchorButton onClick={props.edit} icon="edit" intent={bp.Intent.PRIMARY} outlined/>}
        {props.delete && <bp.AnchorButton onClick={props.delete} icon="trash" intent={bp.Intent.DANGER} outlined/>}
    </>;
}