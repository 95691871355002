import React from 'react';
import ReactDOM from 'react-dom/client';
import * as rr from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
//import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Auth0Provider
        domain="dev--mueeb28.us.auth0.com"
        clientId="X7MOLAaXo4AAiTA9RXeA192379tF6JlE"
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
    >
        <rr.BrowserRouter>
            <App/>
        </rr.BrowserRouter>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
