import React, {useState} from "react";
import * as bp from "@blueprintjs/core";

export type ErrorInfo = {
    message: string,
    developerMessage: string
};

export function ErrorStateView({message, developerMessage}: ErrorInfo) {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    return <bp.NonIdealState icon="error"
                             iconSize={bp.NonIdealStateIconSize.STANDARD}
                             title="Fehler!"
                             description={message}
                             action={<bp.Button icon="expand-all" onClick={() => setShowDetails(!showDetails)}/>}>
        <bp.Collapse isOpen={showDetails}>
            <bp.Pre>{developerMessage}</bp.Pre>
            <bp.Button icon="clipboard"/>
        </bp.Collapse>
    </bp.NonIdealState>;
}

export function EmptyStateView() {
    return <bp.NonIdealState icon="database" title="Keine Ergebnisse"/>;
}

export function LoadingStateView() {
    return <bp.Spinner />;
}